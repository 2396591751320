import { Card, Image as ImageType, ListStyle } from '@yleisradio/areena-types';
import { CardLabels } from 'components/Card/CardLabels';
import { Container } from 'components/Container';
import { Controls } from 'components/Controls';
import { Link } from 'components/Link';
import Image, { getImageProps } from 'next/image';
import * as React from 'react';
import { filterLabelsByType } from 'utils/card';
import {
  backgroundImageLoader,
  loaderUrl,
  squareImageLoader,
} from 'utils/cloudinary';
import { sanitizeHtmlId } from 'utils/ui-helpers';
import styles from './HeroCard.module.scss';
import { GridContainer, GridElement } from 'components/Grid';
import classNames from 'classnames';

type HeroBackgroundProps = {
  image: ImageType;
  imageAspectRatio: ListStyle['image'];
};

const HeroBackground: React.FC<HeroBackgroundProps> = ({
  image,
  imageAspectRatio,
}) => {
  if (imageAspectRatio === '1:1')
    return (
      <div className={styles.backgroundImage}>
        <Image
          alt=""
          src={loaderUrl(image)}
          loader={backgroundImageLoader({
            aspectRatio: '1:1',
            isBlurred: true,
          })}
          fill
          priority
          sizes="100vw"
        />
      </div>
    );

  const { props: landscapeProps } = getImageProps({
    alt: '',
    src: loaderUrl(image),
    loader: backgroundImageLoader({
      aspectRatio: '16:9',
      isBlurred: false,
    }),
    fill: true,
    priority: true,
    sizes: '100vw',
  });

  return (
    <div className={styles.backgroundImage}>
      <picture>
        <source
          media="(min-width: 480px)"
          sizes={landscapeProps.sizes}
          srcSet={landscapeProps.srcSet}
        />
        <img
          {...getImageProps({
            alt: '',
            src: loaderUrl(image),
            loader: backgroundImageLoader({
              aspectRatio: '1:1',
              isBlurred: false,
            }),
            fill: true,
            priority: true,
            sizes: '100vw',
          }).props}
          alt=""
        />
      </picture>
    </div>
  );
};

type Props = {
  card: Card;
  cardKey: string;
  imageAspectRatio?: ListStyle['image'];
};

export const HeroCard: React.FunctionComponent<Props> = ({
  card,
  cardKey,
  imageAspectRatio,
}) => {
  const headingId = sanitizeHtmlId(cardKey + 'title');
  const highlightLabels = filterLabelsByType('highlight', card.labels);
  const controls = card.controls || [];
  const hasSquareImage = imageAspectRatio === '1:1';

  const rootClasses = classNames(
    styles.root,
    hasSquareImage ? styles.rootWithSquareImage : styles.rootWithNoSquareImage
  );

  return (
    <div className={rootClasses} role="group" aria-labelledby={headingId}>
      {card.image && (
        <HeroBackground
          image={card.image}
          imageAspectRatio={imageAspectRatio}
        />
      )}

      <div className={styles.content}>
        <Container>
          <GridContainer>
            <GridElement mobile={2} mobileLandscape={0} />

            {hasSquareImage && (
              <GridElement mobile={8} mobileLandscape={4} desktop={6}>
                <div className={styles.image}>
                  {card.image && (
                    <Image
                      alt=""
                      src={loaderUrl(card.image)}
                      loader={squareImageLoader}
                      priority
                      fill
                      sizes="(min-width: 1400px) 22.5vw, (min-width: 480px) 30vw, 60vw"
                    />
                  )}
                </div>
              </GridElement>
            )}

            <GridElement mobile={2} mobileLandscape={0} />

            <GridElement mobile={12} mobileLandscape={8}>
              <div className={styles.textContent}>
                {highlightLabels.length > 0 && (
                  <div className={styles.highlightLabels}>
                    <CardLabels labels={highlightLabels} isHighlight />
                  </div>
                )}

                <h3 className={styles.title} dir="auto" id={headingId}>
                  {card.pointer ? (
                    <Link pointer={card.pointer} className={styles.link}>
                      {card.title}
                    </Link>
                  ) : (
                    card.title
                  )}
                </h3>

                {card.description ? (
                  <p className={styles.description} dir="auto">
                    {card.description}
                  </p>
                ) : null}

                {controls.length > 0 && (
                  <div className={styles.controls}>
                    <Controls controls={controls} as="button" size="sm" />
                  </div>
                )}
              </div>
            </GridElement>
          </GridContainer>
        </Container>
      </div>
    </div>
  );
};
